html,
body {
    overflow-x: hidden;
}

.content {
    margin: 0 auto;
    max-width: 1440px;
    position: relative;
}

.btn-primary {
    display: inline-block;
    background: #ff5a63;
    color: white;
    border-radius: 28px;
    font-size: 14px;
    line-height: 32px;

    &:hover {
        background: hsla(357, 100%, 75%, 1);
    }

    padding: 4px 20px;

    @media #{$w375-and-up} {
        white-space: nowrap;
        font-size: 16px;
    }

    @media #{$medium-and-up} {
        padding: 12px 25px;
        font-size: 20px;
    }
}

.btn-steam {
    cursor: pointer;
    background: #7763f2;
    border-radius: 8px;
    margin-top: 56px;
    color: #010101;
    color: white;
    font-weight: bold;
    margin-bottom: -12px;

    i {
        margin-right: 15px;
        margin-left: 0px;
    }

    &:hover {
        // background: hsla(357, 100%, 75%, 1);
        background: #6c4ada;
    }
}

.header,
.footer-img {
    width: 100%;

    img {
        width: 100%;
        display: block;
    }
}

.intro {
    // display: flex;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 48px;
}

.logo-block {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &.centered {
        justify-content: center;
        flex-direction: column;

        .logo {
            margin-left: 0;
            width: 90%;
        }
    }

    .logo {
        width: 90%;
        min-width: 30%;
        max-width: 600px;
    }

    // @media #{$large-and-up} {
    //     // justify-content: space-between;
    //     // flex-direction: row;

    //     .logo {
    //         width: 30%;
    //         // margin-left: 5%;
    //     }
    // }
}

.platforms {
    display: flex;
    justify-content: center;

    .platform {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 112px;
        margin-left: 20px;
        color: white;

        &:first-child {
            margin-left: 0;
        }

        img,
        svg {
            display: block;
            max-height: 30px;
        }
    }
}

.stores {
    display: flex;
    justify-content: center;

    a
    {
        &:first-child {
            margin-left: 0;
        }

        margin-left: 16px;

        &:hover {
            // opacity: 0.8;
        }
    }

    .store {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 112px;
        // margin-left: 16px;
        color: white;

        border-radius: 10000px;
        border: 2px solid #FFF;
        padding: 12px 24px;
        padding: 12px 14px;

        align-items: center;

        &:hover
        {
            color: #000;
            border-color: #000;
            border-color: #7763f2;
            border-color: #FF5964;
        }

        &.steam {
            padding: 8px 14px;
        }



        img,
        svg {
            display: block;
            max-height: 30px;
        }
    }
}

.video-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    align-items: center;
    flex-direction: column;


    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    /* margin: auto; */

    // @media #{$large-and-up} {
    //     flex-direction: row;
    // }
}

.video {
    width: 100%;
    min-width: 300px;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .embed {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

.video-desc {
    text-align: center;

    @media #{$large-and-up} {
        margin-left: 10%;

        h6 {
            margin-top: 0;
        }
    }
}

.love-block {
    // width: 740px;
    max-width: 100%;
    margin: 0 auto;
    // margin-top: 90px;
    text-align: center;
    // margin-bottom: 90px;

    @media #{$w400-and-up} {
        max-width: 90%;
    }
}

.available-now-block {
    // width: 740px;
    max-width: 100%;
    margin: 0 auto;
    // margin-top: 90px;
    text-align: center;
    // margin-bottom: 90px;

    h2 {
        margin: 20px 0;
    }

    @media #{$w400-and-up} {
        max-width: 90%;
    }
}


.faq-block {

    max-width: 90%;
    margin: 0 auto;
    width: 950px;
    margin-bottom: 20px;

    h2 {
        font-size: 36px;
        line-height: normal;
        text-align: center;
    }

    .question {
        // text-align: right;
        font-weight: bold;
        font-size: 21px;
    }

    .answer {
        text-align: left;
        a {
            color: #fff;
            border-bottom: 1px solid #fff;
        }
    }

    // p {
    //     font-size: 18px;
    // }

    @media #{$medium-and-up} {
        margin-bottom: 0;

        .question {
            text-align: right;
        }
    }
}

.faq-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;

    @media #{$medium-and-up} {


        grid-template-columns: 1fr 1fr;
        /* grid-template-columns: 0.95fr 1.05fr; */
    
        grid-gap: 0 42px;
    }




    > div {
        padding-top: 42px;
    }

    p {
        margin: 0;
        margin-bottom: 20px;
    }
}

p {
    font-size: 20px;
    line-height: 32px;
    color: #ffffff;
}

.chat-section-temp {
    width: 100%;

    img {
        width: 100%;
    }
}

.chat-section {
    width: 100%;

    .chat {
        display: flex;
        height: 90vh;
        background-color: #f8f7fb;

        .contacts {
            color: $gvh-purple;
            background-color: #efedf6;
            width: 27.5%;
            // max-width: 396px;
            padding: 48px 24px;
            padding: 48px 24px;

            .title {
                user-select: none;
                padding: 0 20px;
                padding-right: 10px;

                display: flex;
                justify-content: space-between;
                font-size: 26px;
                // line-height: 16px;
                text-transform: uppercase;
                font-weight: bold;

                padding-bottom: 16px;
                border-bottom: 2px solid $gvh-purple;

                .text {
                    letter-spacing: 2px;
                }

                .plus {
                    font-size: 35px;
                    cursor: pointer;
                }
            }
        }
    }
}

.chat-window {
    flex-grow: 1;
    height: 100%;
    position: relative;
}

.chat-log {
    height: calc(100% - 60px);
    background: red;
}

.chat-bar {
    border-top: 1px solid #e7ebee;
    position: absolute;
    bottom: 0;
    right: 0px;
    height: 60px;
    width: 290px;
    background: #fff;
    padding-bottom: 50px;
}

.chat-send {
}

.chat-send input {
    background: #fff;
    margin: 21px 0 0 21px;
    border: none;
    padding: 0;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400px;
    color: #aab8c2;
}
.chat-send input:focus {
    outline: 0;
}
.chat-send button {
    background: #fff url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/send.png") 0 -41px no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 23px;
    border: none;
}
.chat-send button:hover {
    cursor: pointer;
    background-position: 0 0;
}
.chat-send button:focus {
    outline: 0;
}

.friends-list {
    margin-top: 32px;

    .contact {
        display: flex;
        align-items: center;
        position: relative;
        padding: 12px;
        margin: 12px 0;

        cursor: pointer;

        .contact-bg {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            // transition: opacity 0.3s cubic-bezier(0.5, 0, 0, 1) 0s;
            transition: opacity 0.2s;

            background: #2e1433;
            border-radius: 8px;
            opacity: 0;
        }

        &:hover .contact-bg {
            opacity: 0.04;
        }

        &.active .contact-bg {
            opacity: 0.05;
        }

        .pfp {
            width: 64px;
            height: 64px;
            border-radius: 100%;
            overflow: hidden;
            background-color: #e4ccfe;
        }

        .username {
            margin-left: 16px;
            font-size: 24px;
            letter-spacing: 2px;
        }
    }
}

.bandcamp-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    // align-items: center;
    flex-direction: column;

    @media #{$large-and-up} {
        flex-direction: row;
    }
}

.bandcamp {
    flex-shrink: 0;

    width: 100%;

    @media #{$large-and-up} {
        width: 50%;
    }

    .pill {
        background: #ffffff;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        color: $gvh-purple;
        padding: 34px;

        h5,
        p {
            color: $gvh-purple;
        }

        h5 {
            margin: 0;
        }
    }
}

.bandcamp-desc {
    text-align: center;
    margin-left: 0;

    h2,
    h6 {
        margin-top: 0;
    }

    h2 {
        margin-top: 50px;
        font-size: 40px;
        line-height: 58px;
    }

    @media #{$large-and-up} {
        margin-left: 5%;
        text-align: right;
    }

    @media #{$extra-large-and-up} {
        margin-left: 9%;

        h2 {
            margin-top: 0;
            font-size: 56px;
            line-height: 72px;
        }
    }
}

.music {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 48px;
}

.phone {
    margin-bottom: -94px;
    z-index: -1;
    position: relative;
}

.tomorrow-block {
    text-align: left;
    width: 90%;

    margin: 0 auto;

    @media #{$large-and-up} {
        width: 80%;
        margin: 0;
        margin-top: 120px;
    }
}

.polaroids-section {
    width: 100%;

    img {
        width: 100%;
    }
}

.debug-buttons {
    display: flex;
    justify-content: space-around;
    margin: 100px;

    div {
        width: 50px;
        height: 50px;
        background-color: white;
        cursor: pointer;
    }
}

.track {
    &:hover {
        background-color: yellow;
    }

    &.playing {
        background-color: red;
    }
}

#play_btn {
    display: inline-block;
    position: relative;
    cursor: pointer;

    color: #6c4ada;
    transition: color 0.2s;

    .symbol {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        i {
            font-size: 44px;
            margin-top: -17px;

            &.fa-play {
                margin-left: 6px;
            }
        }
    }

    .fa-play {
        display: block;
    }
    .fa-stop {
        display: none;
    }

    &:hover {
        color: $gvh-blue;
    }

    &.playing {
        // color: #ffd975;
        // color: #edc55e;
        color: $gvh-blue;

        .fa-play {
            display: none;
        }
        .fa-stop {
            display: block;
        }
    }
}

.band-block {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 70px;
    margin-bottom: 80px;

    @media #{$large-and-up} {
        margin-top: 120px;
    }
}

.instruments {
    display: flex;
    margin-bottom: 36px;
    margin-bottom: 20px;
    margin-bottom: 12px;
    margin-bottom: 4px;
    width: 100%;
    flex-direction: column;
    align-items: center;

    @media #{$large-and-up} {
        flex-direction: row;
        justify-content: space-between;
        align-items: initial;
    }

    @media #{$extra-large-and-up} {
        width: 90%;
    }
}

.track-group {
    // width: 374px;
    width: 30%;
    width: 32%;
    width: 100%;
    max-width: 374px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$large-and-up} {
        width: 32%;
    }

    &.lower {
        @media #{$large-and-up} {
            margin-top: 54px;
        }
    }

    .bubble {
        position: relative;
        height: 42px;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        color: $gvh-purple;
        background-color: white;
        width: 100%;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 2px 27px rgba(0, 0, 0, 0.25);
        user-select: none;

        @media #{$medium-and-up} {
            font-size: 21px;
        }

        @media #{$extra-large-and-up} {
            font-size: 24px;
            height: 51px;
        }
    }

    .cover {
        height: 50px;
        background-color: #2e1433;
        width: 100%;
        position: relative;
    }
}

.wedges-old {
    width: 85%;
    margin-bottom: -1%;

    svg {
        fill: #8e8691;
        display: block;
    }
}

.wedges {
    display: flex;
    // margin-bottom: -115px;
    margin-bottom: -33%;

    .wedge {
        position: relative;
        cursor: pointer;
        > svg {
            width: 100%;
            // width: 127px;
            // height: 203px;

            display: block;

            .base {
                fill: #8e8691;
                transition: fill 0.2s;
            }
            .edge {
                fill: #68616b;
                transition: fill 0.2s;
            }
        }
        // transform-origin: 50% 100%;

        &.left {
            transform: rotate(-45deg);
            position: relative;
            right: -15.5%;
            margin-top: 22px;
            top: 0%;

            .instrument-icon i,
            .instrument-icon svg {
                transform: rotate(45deg);
                margin-left: 19px;
                margin-top: -130px;
                margin-top: -120%;
            }
        }
        &.right {
            transform: rotate(45deg);
            position: relative;
            left: -15.5%;
            margin-top: 22px;
            top: 0%;

            .instrument-icon i,
            .instrument-icon svg {
                transform: rotate(-45deg);
                margin-right: 19px;
                margin-top: -130px;
                margin-top: -120%;
            }
        }

        &:hover {
            .base {
                fill: #58dcff;
            }
            .edge {
                fill: #3ab3d3;
            }
            .instrument-icon i,
            .instrument-icon svg {
                color: white;
            }
        }

        &.playing {
            .base {
                fill: #ffd975 !important;
            }
            .edge {
                fill: #cc9200 !important;
            }
            .instrument-icon i,
            .instrument-icon svg {
                color: #010101 !important;
            }
        }
    }
}

.instrument-icon {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 114%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        user-select: none;
        color: #2e1433;
        font-size: 24px;
        margin-top: -135px;
        margin-top: -120%;
    }

    svg {
        width: 24px;
        color: #2e1433;
        margin-top: -135px;
        margin-top: -120%;
    }
}

#vox_1:hover ~ #vox_3 {
    .base {
        fill: #58dcff;
    }
    .edge {
        fill: #3ab3d3;
    }
    .instrument-icon i,
    .instrument-icon svg {
        color: white;
    }
}

#vox_3:hover ~ #vox_1 {
    .base {
        fill: #58dcff;
    }
    .edge {
        fill: #3ab3d3;
    }
    .instrument-icon i,
    .instrument-icon svg {
        color: white;
    }
}

// .wedges.duo .right {

//     svg {
//         .base {
//             fill: #010101;
//         }
//         .edge {
//             fill: #010101
//         }
//     }
// }

// .wedges.duo .right {

//     visibility: hidden;
// }

// .audio-selector {
//     .inactive {
//         .base {
//             fill: #865EFF;
//         }
//         .edge {
//             fill: #6C4ADA;
//         }
//     }

//     .highlight {

//     }

//     .selected {
//         .base {
//             fill: #3AB3D3;
//         }
//         .edge {
//             fill: #6C4ADA;
//         }
//     }
// }

.outro {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 48px;
}

.bios-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    // align-items: center;
    flex-direction: column-reverse;

    @media #{$large-and-up} {
        flex-direction: row;
    }
}

.bio-description {
    width: 100%;

    @media #{$large-and-up} {
        width: 50%;
    }

    .title {
        font-size: 14px;
        letter-spacing: 3px;
        text-transform: uppercase;
        border-bottom: 2px solid;
        width: 194px;
        margin-bottom: 20px;
        padding-bottom: 5px;
    }

    .name {
        font-size: 55px;
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .nav {
        display: flex;
        justify-content: center;

        @media #{$large-and-up} {
            justify-content: flex-start;
        }

        svg {
            width: 35px;
        }
        .arrow {
            cursor: pointer;
            color: white;

            transition: opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0s;

            display: flex;
            padding: 4px 8px;
            border-radius: 4px;

            &:hover {
                background: rgba(255, 255, 255, 0.05);
            }

            &.disabled {
                background: none;
                cursor: initial;
                opacity: 0.35;
            }

            &.right {
                // margin-left: 24px;
                margin-left: 2px;
            }
        }
    }
}

.bio-polaroid {
    // background: rgba(0, 0, 0, 0.3);
    transform: rotate(3deg);

    width: 100%;

    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    @media #{$large-and-up} {
        width: 40%;
        margin-bottom: 0;
    }

    .frame {
        background: white;
        // width: 400px;
        // height: 450px;

        max-width: 400px;
        max-height: 450px;

        display: flex;
        justify-content: center;
        // right: 0;
        // position: absolute;

        .photo {
            padding: 20px 16px 60px;
            // position: relative;
            // width: 100%;
            // overflow: hidden;
            // padding-top: 50%;

            img {
                height: 100%;
                display: inline-flex;
                object-fit: cover;
            }
        }
    }
}

.yearbook-block {
    margin-top: 32px;

    .naomi {
        // width: 128px;
        // height: 128px;
        width: 112px;
        height: 112px;
        border: 8px solid #ffffff;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
        border-radius: 100%;
        overflow: hidden;
        background: #fff;
        background: #f0eff7;
        position: relative;
        margin: 0 auto;
        top: 26px;

        img {
            position: relative;
        }

        // .inner {
        //     position: absolute;
        //     top: 8px;
        //     bottom: 8px;
        //     left: 8px;
        //     right: 8px;
        //     width: 112px;
        //     height: 112px;
        //     background: #F0EFF7;
        //     background: red;
        //     border-radius: 100%;
        // }
    }

    .pill {
        background: #ffffff;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        color: $gvh-purple;
        padding: 30px 34px;

        h5,
        p {
            color: $gvh-purple;
        }

        h5 {
            margin: 0;
        }
    }

    .parts {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        h5 {
            text-align: center;
        }

        .a {
            max-width: none;
        }

        .b {
            .btn-primary {
                padding: 12px 30px;
            }
        }
    }
}

@media #{$large-and-up} {
    .yearbook-block {
        margin-top: 64px;

        .naomi {
            margin: 0;
            top: 26px;
            left: 34px;
        }

        .parts {
            flex-direction: row;

            .a {
                max-width: 75%;
            }

            h5 {
                text-align: left;
            }
        }
    }
}

.steam {
    svg {
        color: white;
        // transition: color 0.2s;
        width: 100%;

        // &:hover {
        //     color: $gvh-yellow;
        //     color: #ff5a63;
        // }
    }
}

.coming-block {
    // width: 740px;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 80px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
        max-width: 100%;
        width: 770px;
    }

    .coming {
        margin-top: 18px;
        text-align: center;
        max-width: 600px;
        color: white;
        // font-weight: bold;
        font-size: 32px;
        line-height: 50px;

        a {
            color: white;
            border-bottom: 1px solid white;
        }
    }

    .newsletter {
        // margin-top: 20px;
    }
}

.faq-logo-block {
    // width: 740px;
    max-width: 90%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    a {

        max-width: 100%;
        max-width: 80%;
        width: 600px;
    }

    .coming {
        margin-top: 18px;
        text-align: center;
        color: white;
        // font-weight: bold;
        font-size: 25px;
        line-height: initial;

        a {
            color: white;
            border-bottom: 1px solid white;
            margin: 0 2px;
        }
    }

    .newsletter {
        // margin-top: 20px;
    }

    @media #{$large-and-up} {
        .coming {
            font-size: 32p;
            line-height: 50px;
        }
    }
}

input:focus {
    outline: none;
}

.footer {
    color: $gvh-yellow;
    margin-top: 120px;

    a {
        color: $gvh-yellow;
    }

    .footer-bar {
        // background-color: red;
        height: 102px;
        display: flex;
        // justify-content: space-between;
        border-top: 2px solid;
        // padding-top: 20px;
        align-items: center;

        // > div {
        //     width: 33.333%;
        // }

        .right-item {
            margin-left: 20px;
        }

        .socials {
            display: flex;
            height: 60px;
            align-items: center;

            a {
                margin-right: 24px;
            }
        }

        #view_credits {
            margin-left: auto;
            cursor: pointer;
            text-align: center;
            visibility: hidden;
        }

        .presskit {
            text-align: right;

            a:hover {
                border-bottom: 2px solid;
            }
        }
    }
}

.secondary-header {
    color: $gvh-yellow;
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;

    @media #{$large-and-up} {
        margin-bottom: 30px;
    }

    a {
        color: $gvh-yellow;
    }

    .bar {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            width: 33.333%;
        }

        .socials {
            display: flex;
            height: 60px;
            align-items: center;

            a {
                margin-right: 24px;
            }
        }

        .presskit {
            text-align: right;

            a:hover {
                border-bottom: 2px solid;
            }
        }
    }
}

#bio_text {
    min-height: 300px;
    user-select: none;
}

.music-socials {
    font-size: 33px;
    margin: 20px;
    color: white;
    margin-left: 11px;

    a {
        color: white;
        // transition: color 0.2s;

        &:hover {
            color: #ff5a63;
        }
    }

    .fa-spotify {
        margin-right: 7px;
    }
}
