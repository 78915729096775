@font-face {
    font-family: "proxima-soft";
    src: url("./fonts/ProximaSoft-Light.woff2") format("woff2"), url("./fonts/ProximaSoft-Light.woff") format("woff"),
        url("./fonts/ProximaSoft-Light.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "proxima-soft";
    src: url("./fonts/ProximaSoft-LightItalic.woff2") format("woff2"), url("./fonts/ProximaSoft-LightItalic.woff") format("woff"),
        url("./fonts/ProximaSoft-LightItalic.otf") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "proxima-soft";
    src: url("./fonts/ProximaSoft-RegularItalic.woff2") format("woff2"), url("./fonts/ProximaSoft-RegularItalic.woff") format("woff"),
        url("./fonts/ProximaSoft-RegularItalic.otf") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: "proxima-soft";
    src: url("./fonts/ProximaSoft-Regular.woff2") format("woff2"), url("./fonts/ProximaSoft-Regular.woff") format("woff"),
        url("./fonts/ProximaSoft-Regular.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "proxima-soft";
    src: url("./fonts/ProximaSoft-Bold.woff2") format("woff2"), url("./fonts/ProximaSoft-Bold.woff") format("woff"),
        url("./fonts/ProximaSoft-Bold.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 900;
}
