
* {
    box-sizing: border-box;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  body {
    scroll-behavior: smooth;
    margin: 0;
    color: $gvh-yellow;
    font-family: $proxima-soft;
    background-color: $gvh-purple;
  
    font-size: 20px;
    line-height: 32px;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $gvh-yellow;
    font-family: $proxima-soft;
    font-weight: bold;
  }

  h1 {
    font-size: 72px;
    line-height: 96px;
  }

  h2 {
    font-size: 56px;
    line-height: 72px;
  }

  h3 {
    font-size: 48px;
    line-height: 64px;
  }

  h4 {
    font-size: 40px;
    line-height: 48px;
  }

  h5 {
    font-size: 32px;
    line-height: 40px;
  }

  h6 {
    font-size: 24px;
    line-height: 32px;
  }
  
  ol,
  p,
  ul {
  }
  
  a {
    color: initial;
    text-decoration: none;
  }
  
  .nobreak {
    white-space: nowrap;
  }
  
  a:hover {
    cursor: pointer;
  }
  
  b,
  strong {
    font-weight: bolder;
  }
  
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  
  button {
    padding: 0;
    border: none;
    outline: none;
    font: inherit;
    color: inherit;
    background: transparent;
    text-decoration: none;
    cursor: pointer;
  
    &:focus {
      outline: none;
      background-color: initial;
    }
  }
  
  img {
    border-style: none;
    max-width: 100%;
  }
  