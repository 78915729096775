* {
  box-sizing: border-box;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
  color: #fffcdb;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2e1433;
  margin: 0;
  font-family: proxima-soft, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 20px;
  line-height: 32px;
}

h1, h2, h3, h4, h5, h6 {
  color: #fffcdb;
  font-family: proxima-soft, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: bold;
}

h1 {
  font-size: 72px;
  line-height: 96px;
}

h2 {
  font-size: 56px;
  line-height: 72px;
}

h3 {
  font-size: 48px;
  line-height: 64px;
}

h4 {
  font-size: 40px;
  line-height: 48px;
}

h5 {
  font-size: 32px;
  line-height: 40px;
}

h6 {
  font-size: 24px;
  line-height: 32px;
}

a {
  color: initial;
  text-decoration: none;
}

.nobreak {
  white-space: nowrap;
}

a:hover {
  cursor: pointer;
}

b, strong {
  font-weight: bolder;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button {
  font: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  text-decoration: none;
}

button:focus {
  background-color: initial;
  outline: none;
}

img {
  max-width: 100%;
  border-style: none;
}

html, body {
  overflow-x: hidden;
}

.content {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
}

.btn-primary {
  color: #fff;
  background: #ff5a63;
  border-radius: 28px;
  padding: 4px 20px;
  font-size: 14px;
  line-height: 32px;
  display: inline-block;
}

.btn-primary:hover {
  background: #ff8086;
}

@media only screen and (min-width: 375px) {
  .btn-primary {
    white-space: nowrap;
    font-size: 16px;
  }
}

@media only screen and (min-width: 601px) {
  .btn-primary {
    padding: 12px 25px;
    font-size: 20px;
  }
}

.btn-steam {
  cursor: pointer;
  color: #010101;
  color: #fff;
  background: #7763f2;
  border-radius: 8px;
  margin-top: 56px;
  margin-bottom: -12px;
  font-weight: bold;
}

.btn-steam i {
  margin-left: 0;
  margin-right: 15px;
}

.btn-steam:hover {
  background: #6c4ada;
}

.header, .footer-img {
  width: 100%;
}

.header img, .footer-img img {
  width: 100%;
  display: block;
}

.intro {
  max-width: 90%;
  margin: 48px auto 0;
}

.logo-block {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo-block.centered {
  flex-direction: column;
  justify-content: center;
}

.logo-block.centered .logo {
  width: 90%;
  margin-left: 0;
}

.logo-block .logo {
  width: 90%;
  min-width: 30%;
  max-width: 600px;
}

.platforms {
  justify-content: center;
  display: flex;
}

.platforms .platform {
  width: 112px;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  display: flex;
}

.platforms .platform:first-child {
  margin-left: 0;
}

.platforms .platform img, .platforms .platform svg {
  max-height: 30px;
  display: block;
}

.stores {
  justify-content: center;
  display: flex;
}

.stores a {
  margin-left: 16px;
}

.stores a:first-child {
  margin-left: 0;
}

.stores .store {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10000px;
  justify-content: center;
  align-items: center;
  padding: 12px 14px;
  display: flex;
}

.stores .store:hover {
  color: #000;
  border-color: #ff5964;
}

.stores .store.steam {
  padding: 8px 14px;
}

.stores .store img, .stores .store svg {
  max-height: 30px;
  display: block;
}

.video-block {
  width: 100%;
  max-width: 1280px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.video {
  width: 100%;
  min-width: 300px;
}

.video .embed {
  box-shadow: 0 4px 4px #00000040;
}

.video-desc {
  text-align: center;
}

@media only screen and (min-width: 993px) {
  .video-desc {
    margin-left: 10%;
  }

  .video-desc h6 {
    margin-top: 0;
  }
}

.love-block {
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

@media only screen and (min-width: 400px) {
  .love-block {
    max-width: 90%;
  }
}

.available-now-block {
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

.available-now-block h2 {
  margin: 20px 0;
}

@media only screen and (min-width: 400px) {
  .available-now-block {
    max-width: 90%;
  }
}

.faq-block {
  max-width: 90%;
  width: 950px;
  margin: 0 auto 20px;
}

.faq-block h2 {
  text-align: center;
  font-size: 36px;
  line-height: normal;
}

.faq-block .question {
  font-size: 21px;
  font-weight: bold;
}

.faq-block .answer {
  text-align: left;
}

.faq-block .answer a {
  color: #fff;
  border-bottom: 1px solid #fff;
}

@media only screen and (min-width: 601px) {
  .faq-block {
    margin-bottom: 0;
  }

  .faq-block .question {
    text-align: right;
  }
}

.faq-grid {
  grid-gap: 0;
  grid-template-columns: 1fr;
  display: grid;
}

@media only screen and (min-width: 601px) {
  .faq-grid {
    grid-gap: 0 42px;
    grid-template-columns: 1fr 1fr;
  }
}

.faq-grid > div {
  padding-top: 42px;
}

.faq-grid p {
  margin: 0 0 20px;
}

p {
  color: #fff;
  font-size: 20px;
  line-height: 32px;
}

.chat-section-temp, .chat-section-temp img, .chat-section {
  width: 100%;
}

.chat-section .chat {
  height: 90vh;
  background-color: #f8f7fb;
  display: flex;
}

.chat-section .chat .contacts {
  color: #2e1433;
  width: 27.5%;
  background-color: #efedf6;
  padding: 48px 24px;
}

.chat-section .chat .contacts .title {
  -webkit-user-select: none;
  user-select: none;
  text-transform: uppercase;
  border-bottom: 2px solid #2e1433;
  justify-content: space-between;
  padding: 0 10px 16px 20px;
  font-size: 26px;
  font-weight: bold;
  display: flex;
}

.chat-section .chat .contacts .title .text {
  letter-spacing: 2px;
}

.chat-section .chat .contacts .title .plus {
  cursor: pointer;
  font-size: 35px;
}

.chat-window {
  height: 100%;
  flex-grow: 1;
  position: relative;
}

.chat-log {
  height: calc(100% - 60px);
  background: red;
}

.chat-bar {
  height: 60px;
  width: 290px;
  background: #fff;
  border-top: 1px solid #e7ebee;
  padding-bottom: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.chat-send input {
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 400px;
  color: #aab8c2;
  background: #fff;
  border: none;
  margin: 21px 0 0 21px;
  padding: 0;
}

.chat-send input:focus {
  outline: 0;
}

.chat-send button {
  width: 30px;
  height: 30px;
  background: #fff url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/send.png") 0 -41px no-repeat;
  border: none;
  position: absolute;
  top: 23px;
  right: 15px;
}

.chat-send button:hover {
  cursor: pointer;
  background-position: 0 0;
}

.chat-send button:focus {
  outline: 0;
}

.friends-list {
  margin-top: 32px;
}

.friends-list .contact {
  cursor: pointer;
  align-items: center;
  margin: 12px 0;
  padding: 12px;
  display: flex;
  position: relative;
}

.friends-list .contact .contact-bg {
  height: 100%;
  width: 100%;
  opacity: 0;
  background: #2e1433;
  border-radius: 8px;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.friends-list .contact:hover .contact-bg {
  opacity: .04;
}

.friends-list .contact.active .contact-bg {
  opacity: .05;
}

.friends-list .contact .pfp {
  width: 64px;
  height: 64px;
  background-color: #e4ccfe;
  border-radius: 100%;
  overflow: hidden;
}

.friends-list .contact .username {
  letter-spacing: 2px;
  margin-left: 16px;
  font-size: 24px;
}

.bandcamp-block {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 48px;
  display: flex;
}

@media only screen and (min-width: 993px) {
  .bandcamp-block {
    flex-direction: row;
  }
}

.bandcamp {
  width: 100%;
  flex-shrink: 0;
}

@media only screen and (min-width: 993px) {
  .bandcamp {
    width: 50%;
  }
}

.bandcamp .pill {
  color: #2e1433;
  background: #fff;
  border-radius: 16px;
  padding: 34px;
  box-shadow: 0 2px 24px #00000040;
}

.bandcamp .pill h5, .bandcamp .pill p {
  color: #2e1433;
}

.bandcamp .pill h5 {
  margin: 0;
}

.bandcamp-desc {
  text-align: center;
  margin-left: 0;
}

.bandcamp-desc h2, .bandcamp-desc h6 {
  margin-top: 0;
}

.bandcamp-desc h2 {
  margin-top: 50px;
  font-size: 40px;
  line-height: 58px;
}

@media only screen and (min-width: 993px) {
  .bandcamp-desc {
    text-align: right;
    margin-left: 5%;
  }
}

@media only screen and (min-width: 1201px) {
  .bandcamp-desc {
    margin-left: 9%;
  }

  .bandcamp-desc h2 {
    margin-top: 0;
    font-size: 56px;
    line-height: 72px;
  }
}

.music {
  max-width: 90%;
  margin: 48px auto 0;
}

.phone {
  z-index: -1;
  margin-bottom: -94px;
  position: relative;
}

.tomorrow-block {
  text-align: left;
  width: 90%;
  margin: 0 auto;
}

@media only screen and (min-width: 993px) {
  .tomorrow-block {
    width: 80%;
    margin: 120px 0 0;
  }
}

.polaroids-section, .polaroids-section img {
  width: 100%;
}

.debug-buttons {
  justify-content: space-around;
  margin: 100px;
  display: flex;
}

.debug-buttons div {
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-color: #fff;
}

.track:hover {
  background-color: #ff0;
}

.track.playing {
  background-color: red;
}

#play_btn {
  cursor: pointer;
  color: #6c4ada;
  transition: color .2s;
  display: inline-block;
  position: relative;
}

#play_btn .symbol {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

#play_btn .symbol i {
  margin-top: -17px;
  font-size: 44px;
}

#play_btn .symbol i.fa-play {
  margin-left: 6px;
}

#play_btn .fa-play {
  display: block;
}

#play_btn .fa-stop {
  display: none;
}

#play_btn:hover, #play_btn.playing {
  color: #7763f2;
}

#play_btn.playing .fa-play {
  display: none;
}

#play_btn.playing .fa-stop {
  display: block;
}

.band-block {
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 80px;
  display: flex;
}

@media only screen and (min-width: 993px) {
  .band-block {
    margin-top: 120px;
  }
}

.instruments {
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

@media only screen and (min-width: 993px) {
  .instruments {
    justify-content: space-between;
    align-items: initial;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1201px) {
  .instruments {
    width: 90%;
  }
}

.track-group {
  width: 30%;
  width: 32%;
  width: 100%;
  max-width: 374px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 993px) {
  .track-group {
    width: 32%;
  }

  .track-group.lower {
    margin-top: 54px;
  }
}

.track-group .bubble {
  height: 42px;
  text-align: center;
  color: #2e1433;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  position: relative;
  box-shadow: 0 2px 27px #00000040;
}

@media only screen and (min-width: 601px) {
  .track-group .bubble {
    font-size: 21px;
  }
}

@media only screen and (min-width: 1201px) {
  .track-group .bubble {
    height: 51px;
    font-size: 24px;
  }
}

.track-group .cover {
  height: 50px;
  width: 100%;
  background-color: #2e1433;
  position: relative;
}

.wedges-old {
  width: 85%;
  margin-bottom: -1%;
}

.wedges-old svg {
  fill: #8e8691;
  display: block;
}

.wedges {
  margin-bottom: -33%;
  display: flex;
}

.wedges .wedge {
  cursor: pointer;
  position: relative;
}

.wedges .wedge > svg {
  width: 100%;
  display: block;
}

.wedges .wedge > svg .base {
  fill: #8e8691;
  transition: fill .2s;
}

.wedges .wedge > svg .edge {
  fill: #68616b;
  transition: fill .2s;
}

.wedges .wedge.left {
  margin-top: 22px;
  position: relative;
  top: 0%;
  right: -15.5%;
  transform: rotate(-45deg);
}

.wedges .wedge.left .instrument-icon i, .wedges .wedge.left .instrument-icon svg {
  margin-top: -120%;
  margin-left: 19px;
  transform: rotate(45deg);
}

.wedges .wedge.right {
  margin-top: 22px;
  position: relative;
  top: 0%;
  left: -15.5%;
  transform: rotate(45deg);
}

.wedges .wedge.right .instrument-icon i, .wedges .wedge.right .instrument-icon svg {
  margin-top: -120%;
  margin-right: 19px;
  transform: rotate(-45deg);
}

.wedges .wedge:hover .base {
  fill: #58dcff;
}

.wedges .wedge:hover .edge {
  fill: #3ab3d3;
}

.wedges .wedge:hover .instrument-icon i, .wedges .wedge:hover .instrument-icon svg {
  color: #fff;
}

.wedges .wedge.playing .base {
  fill: #ffd975 !important;
}

.wedges .wedge.playing .edge {
  fill: #cc9200 !important;
}

.wedges .wedge.playing .instrument-icon i, .wedges .wedge.playing .instrument-icon svg {
  color: #010101 !important;
}

.instrument-icon {
  pointer-events: none;
  width: 100%;
  height: 114%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.instrument-icon i {
  -webkit-user-select: none;
  user-select: none;
  color: #2e1433;
  margin-top: -120%;
  font-size: 24px;
}

.instrument-icon svg {
  width: 24px;
  color: #2e1433;
  margin-top: -120%;
}

#vox_1:hover ~ #vox_3 .base {
  fill: #58dcff;
}

#vox_1:hover ~ #vox_3 .edge {
  fill: #3ab3d3;
}

#vox_1:hover ~ #vox_3 .instrument-icon i, #vox_1:hover ~ #vox_3 .instrument-icon svg {
  color: #fff;
}

#vox_3:hover ~ #vox_1 .base {
  fill: #58dcff;
}

#vox_3:hover ~ #vox_1 .edge {
  fill: #3ab3d3;
}

#vox_3:hover ~ #vox_1 .instrument-icon i, #vox_3:hover ~ #vox_1 .instrument-icon svg {
  color: #fff;
}

.outro {
  max-width: 90%;
  margin: 48px auto 0;
}

.bios-block {
  width: 100%;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-top: 48px;
  display: flex;
}

@media only screen and (min-width: 993px) {
  .bios-block {
    flex-direction: row;
  }
}

.bio-description {
  width: 100%;
}

@media only screen and (min-width: 993px) {
  .bio-description {
    width: 50%;
  }
}

.bio-description .title {
  letter-spacing: 3px;
  text-transform: uppercase;
  width: 194px;
  border-bottom: 2px solid;
  margin-bottom: 20px;
  padding-bottom: 5px;
  font-size: 14px;
}

.bio-description .name {
  margin-top: 35px;
  margin-bottom: 35px;
  font-size: 55px;
}

.bio-description .nav {
  justify-content: center;
  display: flex;
}

@media only screen and (min-width: 993px) {
  .bio-description .nav {
    justify-content: flex-start;
  }
}

.bio-description .nav svg {
  width: 35px;
}

.bio-description .nav .arrow {
  cursor: pointer;
  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  transition: opacity .6s cubic-bezier(.5, 0, 0, 1);
  display: flex;
}

.bio-description .nav .arrow:hover {
  background: #ffffff0d;
}

.bio-description .nav .arrow.disabled {
  cursor: initial;
  opacity: .35;
  background: none;
}

.bio-description .nav .arrow.right {
  margin-left: 2px;
}

.bio-polaroid {
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
  display: flex;
  transform: rotate(3deg);
}

@media only screen and (min-width: 993px) {
  .bio-polaroid {
    width: 40%;
    margin-bottom: 0;
  }
}

.bio-polaroid .frame {
  max-width: 400px;
  max-height: 450px;
  background: #fff;
  justify-content: center;
  display: flex;
}

.bio-polaroid .frame .photo {
  padding: 20px 16px 60px;
}

.bio-polaroid .frame .photo img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: inline-flex;
}

.yearbook-block {
  margin-top: 32px;
}

.yearbook-block .naomi {
  width: 112px;
  height: 112px;
  background: #f0eff7;
  border: 8px solid #fff;
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
  top: 26px;
  overflow: hidden;
  box-shadow: 0 2px 24px #00000026;
}

.yearbook-block .naomi img {
  position: relative;
}

.yearbook-block .pill {
  color: #2e1433;
  background: #fff;
  border-radius: 16px;
  padding: 30px 34px;
  box-shadow: 0 2px 24px #00000040;
}

.yearbook-block .pill h5, .yearbook-block .pill p {
  color: #2e1433;
}

.yearbook-block .pill h5 {
  margin: 0;
}

.yearbook-block .parts {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.yearbook-block .parts h5 {
  text-align: center;
}

.yearbook-block .parts .a {
  max-width: none;
}

.yearbook-block .parts .b .btn-primary {
  padding: 12px 30px;
}

@media only screen and (min-width: 993px) {
  .yearbook-block {
    margin-top: 64px;
  }

  .yearbook-block .naomi {
    margin: 0;
    top: 26px;
    left: 34px;
  }

  .yearbook-block .parts {
    flex-direction: row;
  }

  .yearbook-block .parts .a {
    max-width: 75%;
  }

  .yearbook-block .parts h5 {
    text-align: left;
  }
}

.steam svg {
  color: #fff;
  width: 100%;
}

.coming-block {
  max-width: 90%;
  flex-direction: column;
  align-items: center;
  margin: 80px auto 0;
  display: flex;
}

.coming-block .logo {
  max-width: 100%;
  width: 770px;
}

.coming-block .coming {
  text-align: center;
  max-width: 600px;
  color: #fff;
  margin-top: 18px;
  font-size: 32px;
  line-height: 50px;
}

.coming-block .coming a {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.faq-logo-block {
  max-width: 90%;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.faq-logo-block a {
  max-width: 100%;
  max-width: 80%;
  width: 600px;
}

.faq-logo-block .coming {
  text-align: center;
  color: #fff;
  font-size: 25px;
  line-height: initial;
  margin-top: 18px;
}

.faq-logo-block .coming a {
  color: #fff;
  border-bottom: 1px solid #fff;
  margin: 0 2px;
}

@media only screen and (min-width: 993px) {
  .faq-logo-block .coming {
    font-size: 32p;
    line-height: 50px;
  }
}

input:focus {
  outline: none;
}

.footer {
  color: #fffcdb;
  margin-top: 120px;
}

.footer a {
  color: #fffcdb;
}

.footer .footer-bar {
  height: 102px;
  border-top: 2px solid;
  align-items: center;
  display: flex;
}

.footer .footer-bar .right-item {
  margin-left: 20px;
}

.footer .footer-bar .socials {
  height: 60px;
  align-items: center;
  display: flex;
}

.footer .footer-bar .socials a {
  margin-right: 24px;
}

.footer .footer-bar #view_credits {
  cursor: pointer;
  text-align: center;
  visibility: hidden;
  margin-left: auto;
}

.footer .footer-bar .presskit {
  text-align: right;
}

.footer .footer-bar .presskit a:hover {
  border-bottom: 2px solid;
}

.secondary-header {
  color: #fffcdb;
  max-width: 90%;
  margin: 0 auto 30px;
}

@media only screen and (min-width: 993px) {
  .secondary-header {
    margin-bottom: 30px;
  }
}

.secondary-header a {
  color: #fffcdb;
}

.secondary-header .bar {
  height: 80px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.secondary-header .bar > div {
  width: 33.333%;
}

.secondary-header .bar .socials {
  height: 60px;
  align-items: center;
  display: flex;
}

.secondary-header .bar .socials a {
  margin-right: 24px;
}

.secondary-header .bar .presskit {
  text-align: right;
}

.secondary-header .bar .presskit a:hover {
  border-bottom: 2px solid;
}

#bio_text {
  min-height: 300px;
  -webkit-user-select: none;
  user-select: none;
}

.music-socials {
  color: #fff;
  margin: 20px 20px 20px 11px;
  font-size: 33px;
}

.music-socials a {
  color: #fff;
}

.music-socials a:hover {
  color: #ff5a63;
}

.music-socials .fa-spotify {
  margin-right: 7px;
}

.mce-input-row {
  height: 56px;
  background: #fff;
  border-radius: 32px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#mc-embedded-subscribe {
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: #7763f2;
  border-radius: 100%;
  margin-right: 10px;
}

#mce-EMAIL {
  height: 100%;
  border: none;
  margin-left: 45px;
}

#mc_embed_signup_scroll h5 {
  text-align: center;
}

@font-face {
  font-family: proxima-soft;
  src: url("ProximaSoft-Light.04b3c0e8.woff2") format("woff2"), url("ProximaSoft-Light.cfb65109.woff") format("woff"), url("ProximaSoft-Light.06cac104.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: proxima-soft;
  src: url("ProximaSoft-LightItalic.9b348b11.woff2") format("woff2"), url("ProximaSoft-LightItalic.0916c7f8.woff") format("woff"), url("ProximaSoft-LightItalic.8baaed7e.otf") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: proxima-soft;
  src: url("ProximaSoft-RegularItalic.2189deec.woff2") format("woff2"), url("ProximaSoft-RegularItalic.0631b345.woff") format("woff"), url("ProximaSoft-RegularItalic.8af0b7e5.otf") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: proxima-soft;
  src: url("ProximaSoft-Regular.26170614.woff2") format("woff2"), url("ProximaSoft-Regular.ac0d33ff.woff") format("woff"), url("ProximaSoft-Regular.1c945517.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: proxima-soft;
  src: url("ProximaSoft-Bold.e73a1052.woff2") format("woff2"), url("ProximaSoft-Bold.267b58ca.woff") format("woff"), url("ProximaSoft-Bold.c1c4f9c5.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

.fa, .fab, .fad, .fal, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  width: 2em;
  line-height: inherit;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fab.fa-pull-left, .fal.fa-pull-left, .far.fa-pull-left, .fas.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fab.fa-pull-right, .fal.fa-pull-right, .far.fa-pull-right, .fas.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  animation: 1s steps(8, end) infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical, .fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

:root .fa-flip-both, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270 {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  height: 2em;
  vertical-align: middle;
  width: 2.5em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-acquisitions-incorporated:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-adobe:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-atlas:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-bahai:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-band-aid:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-baseball-ball:before {
  content: "";
}

.fa-basketball-ball:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-tissue:before {
  content: "拏";
}

.fa-boxes:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-dailymotion:before {
  content: "勒";
}

.fa-dashcube:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-dolly:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-equals:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-euro-sign:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-faucet:before {
  content: "串";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "龜";
}

.fa-first-aid:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-football-ball:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-golf-ball:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "樂";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "諾";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-wash:before {
  content: "丹";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt-slash:before {
  content: "寧";
}

.fa-handshake-slash:before {
  content: "怒";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "率";
}

.fa-head-side-cough-slash:before {
  content: "異";
}

.fa-head-side-mask:before {
  content: "北";
}

.fa-head-side-virus:before {
  content: "磻";
}

.fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-hospital:before {
  content: "";
}

.fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hot-tub:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-user:before {
  content: "便";
}

.fa-houzz:before {
  content: "";
}

.fa-hryvnia:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before {
  content: "";
}

.fa-ideal:before {
  content: "邏";
}

.fa-igloo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-instagram-square:before {
  content: "凌";
}

.fa-intercom:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-house:before {
  content: "復";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "不";
}

.fa-lyft:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-microblog:before {
  content: "駱";
}

.fa-microchip:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-mixer:before {
  content: "稜";
}

.fa-mizuni:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-monero:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-parking:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pencil-alt:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-penny-arcade:before {
  content: "";
}

.fa-people-arrows:before {
  content: "泌";
}

.fa-people-carry:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-percentage:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-square:before {
  content: "爛";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-plane-slash:before {
  content: "數";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-storm:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-pump-medical:before {
  content: "索";
}

.fa-pump-soap:before {
  content: "參";
}

.fa-pushed:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-quidditch:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-ruble-sign:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee-sign:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-shekel-sign:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-virus:before {
  content: "塞";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shopify:before {
  content: "綾";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-sign:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-sliders-h:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-soap:before {
  content: "葉";
}

.fa-socks:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-root-alt:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "說";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-store-alt-slash:before {
  content: "殺";
}

.fa-store-slash:before {
  content: "辰";
}

.fa-strava:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-tennis:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-tenge:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "沈";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-trailer:before {
  content: "論";
}

.fa-train:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-unity:before {
  content: "雷";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-virus:before {
  content: "若";
}

.fa-virus-slash:before {
  content: "掠";
}

.fa-viruses:before {
  content: "略";
}

.fa-vk:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volleyball-ball:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-won-sign:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.8cbcf94f.eot");
  src: url("fa-brands-400.8cbcf94f.eot#iefix") format("embedded-opentype"), url("fa-brands-400.ccd4367d.woff2") format("woff2"), url("fa-brands-400.42a366ea.woff") format("woff"), url("fa-brands-400.07f7396f.ttf") format("truetype"), url("fa-brands-400.9058b386.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.205b19bc.eot");
  src: url("fa-regular-400.205b19bc.eot#iefix") format("embedded-opentype"), url("fa-regular-400.d2526071.woff2") format("woff2"), url("fa-regular-400.8ac56b9e.woff") format("woff"), url("fa-regular-400.787d5e9c.ttf") format("truetype"), url("fa-regular-400.21aab298.svg#fontawesome") format("svg");
}

.fab, .far {
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.56a336d1.eot");
  src: url("fa-solid-900.56a336d1.eot#iefix") format("embedded-opentype"), url("fa-solid-900.2dd19dd0.woff2") format("woff2"), url("fa-solid-900.c7a67308.woff") format("woff"), url("fa-solid-900.d4e0ff2f.ttf") format("truetype"), url("fa-solid-900.5e1bef1f.svg#fontawesome") format("svg");
}

.fa, .far, .fas {
  font-family: "Font Awesome 5 Free";
}

.fa, .fas {
  font-weight: 900;
}

/*# sourceMappingURL=index.58121eb3.css.map */
