.mce-input-row {
    display: flex;
    // justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 32px;
    height: 56px;
    justify-content: space-between;
}

// #mc-embedded-subscribe {
//     -webkit-appearance: none;
//     border-radius: 0;
//     cursor: pointer;
//     padding: 0 16px;
//     font-size: 15px;

//     display: inline-block;
//     text-decoration: none;
//     height: 46px;

//     background: #fdcedd;
//     border: none;
//     color: #666;


//     width: 40px;
//     height: 40px;

//     // @media #{$medium-and-up} {
//     //     height: 56px;
//     // }

//     // @media #{$large-and-up} {
//     //     padding: 12px 32px;
//     //     font-size: 16px;
//     // }
// }

#mc-embedded-subscribe {
    width: 40px;
    height: 40px;
    background-color: $gvh-blue;
    color: white;
    border-radius: 100%;
    margin-right: 10px;
}

#mce-EMAIL {
    margin-left: 45px;
    height: 100%;

    border: none;
}

#mc_embed_signup_scroll {
    h5 {
        text-align: center;
    }
}
